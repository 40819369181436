import React from 'react';
import Swiper from 'react-id-swiper';
import { mainColor } from 'constants/theme';
import ChevronRight from 'icons/chevron_right.svg';
// import ChevronLeft from 'icons/chevron_left.svg';
import { Slide, SliderWrapper, Name, Action } from './portfolioSlider.css';
import '../imageSlider/swiper.css';

class PortfolioSlider extends React.Component {
  render() {
    const slides = this.props.slides;
    const params = {
      navigation: {
        nextEl: '.swiper-button-next',
        // prevEl: '.swiper-button-prev',
      },
      grabCursor: true,
      //   centeredSlides: true,
      slidesPerView: 1.2,
      loop: true,
      loopedSlides: 2,
      spaceBetween: 150,
      breakpoints: {
        990: {
          spaceBetween: 75,
          slidesPerView: 1,
        },
      },
      // renderPrevButton: () => (
      //     <Action className="swiper-button-prev">
      //         <ChevronLeft height="32px" fill={mainColor} />
      //     </Action>
      // ),
    };
    return (
      <SliderWrapper>
        <Swiper {...params}>
          {slides.map((slide, index) => {
            const slideField = JSON.parse(slide.node.field);

            return (
              <Slide index={index} key={index}>
                <div className="portfolio-content">
                  <Name>{slideField.fields.short}</Name>
                  <div className="col col-vertical">
                    <div className="image-container">
                      <img
                        className="image-1"
                        src={
                          'https://cms.burobork.nl/images/' +
                          slideField.fields['Images'][0].reference
                        }
                        alt={slideField.fields['Images'][0].title}
                      />
                    </div>
                    <div className="small-images">
                      <div className="image-container">
                        <img
                          className="image-1"
                          src={
                            'https://cms.burobork.nl/images/' +
                            slideField.fields['Images'][2].reference
                          }
                          alt={slideField.fields['Images'][2].title}
                        />
                      </div>
                      <div className="image-container">
                        <img
                          className="image-1"
                          src={
                            'https://cms.burobork.nl/images/' +
                            slideField.fields['Images'][3].reference
                          }
                          alt={slideField.fields['Images'][3].title}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col col-content">
                    <div className="col-content-text">
                      <h2>{slideField.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: slideField.fields['Korte beschrijving'].nl,
                        }}
                      />
                    </div>
                    <div className="image-container">
                      <img
                        className="image-1"
                        src={
                          'https://cms.burobork.nl/images/' +
                          slideField.fields['Images'][1].reference
                        }
                        alt={slideField.fields['Images'][1].title}
                      />
                    </div>
                  </div>
                </div>
                <Action className="swiper-button-next">
                  <ChevronRight height="32px" fill={mainColor} />
                </Action>
              </Slide>
            );
          })}
        </Swiper>
      </SliderWrapper>
    );
  }
}

export default PortfolioSlider;
