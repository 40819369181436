import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Slide = styled.div`
  background: rgba(196, 196, 196, 0.15);
  padding: 40px 100px 40px 0;
  box-sizing: border-box;
  height: 100%;
  opacity: 1;
  ${MEDIA.TABLET`
    padding: 16px;
  `};
  .portfolio-content {
    display: flex;
    ${MEDIA.TABLET`
        flex-direction: column;
    `};
    .col {
      width: 45%;
      ${MEDIA.TABLET`
       width: 100%;
    `};
      &.col-vertical {
        display: flex;
        flex-direction: column;
        .small-images {
          padding: 32px 0 0;
          display: flex;
          ${MEDIA.TABLET`
          display: none;
    `};
          .image-container:first-of-type {
            margin: 0 32px 0 0;
          }
        }
      }
      &.col-content {
        .col-content-text {
          min-height: 250px;
          ${MEDIA.TABLET`
        min-height: auto;
        padding: 16px 0;
    `};
          h2 {
            font-size: 2.4rem;
            line-height: 1.4rem;
            font-weight: 700;
            padding: 4px 0 16px;
          }
        }
        padding: 0 32px 32px;
        ${MEDIA.TABLET`
          padding: 0;
    `};
      }
    }
    .image-container {
      img {
        max-width: 100%;
        box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
      }
    }
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  .swiper-button-next {
    display: none;
  }
`;

export const Name = styled.div`
  writing-mode: vertical-lr;
  font-family: 'Muli';
  transform: rotate(180deg);
  text-align: right;
  font-size: 86px;
  color: #30467d;
  font-weight: 900;
  line-height: 70px;
  vertical-align: middle;
  ${MEDIA.TABLET`
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
    writing-mode: horizontal-tb;
    mix-blend-mode: multiply;
    z-index: 99;
         font-size: 42px;
         line-height: 32px;
  `};
`;

export const Action = styled.button`
  &.swiper-button-next {
    right: -32px !important;
    ${MEDIA.TABLET`
          right: 0 !important;
          top: 0 !important;
          margin: 0 !important;
    `};
  }
  display: block !important;
  background-image: none !important;
  appearance: none;
  border: 0;
  background: #30467d;
  padding: 16px 24px;
  display: flex;
  height: auto !important;
  width: auto !important;
  align-items: center;
  justify-content: center;
  box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.25);
  ${MEDIA.TABLET`
          padding: 8px 16px;
    `};
  svg {
    fill: #fff;
  }
`;
