import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  padding: 100px 0 100px 50px;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  ${MEDIA.TABLET`
    padding: 100px 16px 50px;
  `};
`;
