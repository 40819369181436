import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import 'aos/dist/aos.css';
import Layout from 'components/layout';
import Head from 'components/head';
import Box from 'components/box';
import PageBlocks from 'components/pageBlocks';
import { Container } from '../portfolio.css';
import PortfolioSlider from 'components/portfolioSlider';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = { didMount: false };
  }

  componentDidMount() {
    const AOS = require('aos');
    this.aos = AOS;
    this.aos.init();
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    const fields = JSON.parse(this.props.data.buroBorkPage.field);
    const slides = this.props.data.allItems.edges;

    return (
      <Layout locale={fields.locale}>
        <Head pageTitle={fields.title} locale={fields.locale} />
        <Container>
          <h1>Portfolio</h1>
          <PortfolioSlider slides={slides} />
        </Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'stretch',
            width: '100%',
            position: 'relative',
          }}
        />
        {fields.page_rows.map((row, index) => {
          let classes = 'page_row';
          if (row.options && row.options.classes) {
            classes = classNames(classes, Object.values(row.options.classes));
          }
          return (
            <Box key={index} className={classes}>
              <PageBlocks blocks={row.page_blocks} />
            </Box>
          );
        })}
      </Layout>
    );
  }
}

Portfolio.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query BuroBorkPortfolio {
    buroBorkPage(slug: { eq: "/portfolio" }) {
      id
      field
    }
    allItems {
      edges {
        node {
          id
          field
        }
      }
    }
  }
`;

export default Portfolio;
